<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Facturacion</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Facturacion</li>
                  <li class="breadcrumb-item active">Facturacion</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table">
                  <thead class="thead-dark">
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Fecha inicial</th>
                      <th>Fecha final</th>
                      <th>Estado</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in facturas.data" :key="item.id">
                      <th>{{ item.id }}</th>
                      <th>{{ item.nombre }}</th>
                      <th>{{ item.fecha_inicio }}</th>
                      <th>{{ item.fecha_fin }}</th>
                      <th>{{ item.nEstado }}</th>
                      <th>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('tep.facturacion.show')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('tep.facturacion.delete')"
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="facturas.total">
                  <p>
                    Mostrando del <b>{{ facturas.from }}</b> al
                    <b>{{ facturas.to }}</b> de un total:
                    <b>{{ facturas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="facturas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";

import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "TepFacturacionIndex",
  components: {
    Loading,
    pagination,
  },

  data() {
    return {
      cargando: false,
      filtros: {},
      facturas: {},
      listasForms: {},
    };
  },

  methods: {
    create() {
      return this.$router.push({
        name: "/Tep/FacturacionForm",
        params: { accion: "Crear" },
      });
    },

    edit(facturacion) {
      return this.$router.push({
        name: "/Tep/FacturacionForm",
        params: {
          accion: "Editar",
          data_edit: facturacion,
          id: facturacion.id,
        },
      });
    },

    getIndex(page = 1) {
      axios
        .get("/api/tep/facturacion?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.facturas = response.data;
        });
    },

    destroy(idFacturacion) {
      this.$swal({
        title: "Esta seguro de eliminar esta Facturación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("api/tep/facturacion/" + idFacturacion).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Facturación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getEstados();
  },
};
</script>
